const iconBanner = document.querySelector('.icon-banner.paginate');

if (iconBanner) {
    document.addEventListener("DOMContentLoaded", () => {
        const page = iconBanner.dataset.page;
        paginateIcons(page);
    })

    const addMore = iconBanner.querySelector('.js-load-more-photos');
    if (addMore) {
        addMore.addEventListener('click', () => {
            const page = Number(iconBanner.dataset.page) + 1;

            paginateIcons(page)
            iconBanner.dataset.page++;
        })
    }

    function paginateIcons(page) {
        count = 0;
        const icons = iconBanner.querySelectorAll('.tile-card');
        const moreButton = iconBanner.querySelector('.js-load-more-photos');
        icons.forEach(icon => {
            if (count < (page * 8)) {
                icon.classList.remove('hidden');
            } else {
                icon.classList.add('hidden');
            }
            count++;
        })
        const hiddenIcons = iconBanner.querySelectorAll(".tile-card.hidden");

        if (hiddenIcons.length == 0) {
            moreButton.disabled = true;
            moreButton.style.visibility = "hidden";
        } else {
            moreButton.disabled = false;
            moreButton.style.visibility = "visible";
        }
    }
}
