document.addEventListener("click", function (e) {
    if (e.target.matches(".js-blog-btn")) {
        var btns = document.querySelectorAll(".js-blog-btn");
        btns.forEach(function (it) {
            it.classList.remove("active");
        });
        e.target.classList.add("active");

        filterCommunityPhotos(e.target.getAttribute("data-location"));
    }
});

function filterCommunityPhotos() {
    var arts = document.querySelectorAll(".photos .comm-photo-card");

    arts.forEach(function (it) {
        if (cat=="all" || it.getAttribute("data-location").indexOf(cat) != -1) {
            it.style.display = "block";
        }
        else {
            it.style.display = "none";
        }
    });
}

// Standard Link Copy Button //
let linkShare = document.querySelectorAll('.link-btn');

linkShare.forEach(el => {
    el.addEventListener('click', () => {
        navigator.clipboard.writeText(window.location.href)
    });
});
