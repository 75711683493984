const communityPhotos = document.querySelector('.community-photos');

if (communityPhotos) {
    document.addEventListener('DOMContentLoaded', () => {
        filterCommunityPhotos('all', 1);
    })
    document.addEventListener("click", (e) => {
        if (e.target.matches(".js-comm-photos")) {
            var btns = document.querySelectorAll(".js-comm-photos");
            var parent = getParentElementWithClass(e.target, "community-photos");
            const page = parent.dataset.page;
            btns.forEach((it) => {
                it.classList.remove("active");
            });
            e.target.classList.add("active");

            parent.dataset.currentFilter = e.target.getAttribute("data-location");

            filterCommunityPhotos(e.target.getAttribute("data-location"), page);
            parent.dataset.page = "1";
        } else if (e.target.matches('.js-load-more-photos')) {
            var parent = getParentElementWithClass(e.target, "community-photos");
            parent.dataset.page = Number(parent.dataset.page) + 1;

            filterCommunityPhotos(parent.dataset.currentFilter, parent.dataset.page);
        }
    });

    function filterCommunityPhotos(location, page) {
        let photos = document.querySelectorAll(".comm-photo-card");
        const limit = page * 9;
        let count = 0;

        let amountOfImages = 0;

        photos.forEach(i => {
            if (location === "all" || i.getAttribute("data-location").indexOf(location) != -1) {
                amountOfImages++
            }
        })

        photos.forEach((i) => {
            if ((location === "all" || i.getAttribute("data-location").indexOf(location) != -1) && (count < limit)) {
                i.style.display = "block"; // Ensure it's in the layout
                requestAnimationFrame(() => {
                    i.classList.remove("hidden"); // Trigger transition
                });
                count++;
            } else {
                i.classList.add("hidden"); // Start fade-out
                i.addEventListener('transitionend', function handleTransitionEnd() {
                    if (i.classList.contains("hidden")) {
                        i.style.display = "none"; // Hide after fade-out
                    }
                    i.removeEventListener('transitionend', handleTransitionEnd); // Clean up
                });
            }
        });

        if (amountOfImages > limit) {
            document.querySelector('.js-load-more-photos').style.display = "inline-block";
        } else {
            document.querySelector('.js-load-more-photos').style.display = "none";
        }
    }
}

